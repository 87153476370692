import { ERouteHttpVerb } from "@devowl-wp/api";
const routeLocationSupportedLanguagesGet = {
    path: "/supported-languages",
    method: ERouteHttpVerb.GET
};
var ESupportedLanguage;
/**
 * Listing our supported languages.
 *
 * @see https://app.clickup.com/t/863g5edjt (CU-863g5edjt)
 */ (function(ESupportedLanguage) {
    ESupportedLanguage["cs"] = "cs";
    ESupportedLanguage["da"] = "da";
    ESupportedLanguage["nl"] = "nl";
    ESupportedLanguage["nl_formal"] = "nl_formal";
    ESupportedLanguage["nl_informal"] = "nl_informal";
    ESupportedLanguage["en"] = "en";
    ESupportedLanguage["es"] = "es";
    ESupportedLanguage["fi"] = "fi";
    ESupportedLanguage["fr"] = "fr";
    ESupportedLanguage["de"] = "de";
    ESupportedLanguage["de_formal"] = "de_formal";
    ESupportedLanguage["de_informal"] = "de_informal";
    ESupportedLanguage["el"] = "el";
    ESupportedLanguage["hu"] = "hu";
    ESupportedLanguage["it"] = "it";
    ESupportedLanguage["no"] = "no";
    ESupportedLanguage["pl"] = "pl";
    ESupportedLanguage["pt"] = "pt";
    ESupportedLanguage["rm"] = "rm";
    ESupportedLanguage["sk"] = "sk";
    ESupportedLanguage["sv"] = "sv";
})(ESupportedLanguage || (ESupportedLanguage = {}));
/**
 * Listing our supported languages mapped to known DeepL API languages.
 */ const deeplLanguageMap = {
    ["cs"]: "cs",
    ["da"]: "da",
    ["nl"]: "nl",
    ["nl_formal"]: "nl_formal",
    ["nl_informal"]: "nl_informal",
    ["en"]: "en",
    ["es"]: "es",
    ["fi"]: "fi",
    ["fr"]: "fr",
    ["de"]: "de",
    ["de_formal"]: "de_formal",
    ["de_informal"]: "de_informal",
    ["el"]: "el",
    ["hu"]: "hu",
    ["it"]: "it",
    ["no"]: "nb",
    ["pl"]: "pl",
    ["pt"]: "pt-PT",
    ["rm"]: "ro",
    ["sk"]: "sk",
    ["sv"]: "sv"
};
Object.freeze(deeplLanguageMap);
/**
 * Listing our supported languages mapped to known DeepL supported target glossary language codes.
 * Note that some languages are missing because they are not supported by the DeepL API for glossaries.
 *
 * Unsupported languages for glossaries: Czech (cs), Finnish (fi), Greek (el), Hungarian (hu), Slovak (sk)
 *
 * @see https://developers.deepl.com/docs/api-reference/glossaries
 * @see https://app.clickup.com/t/8692xtha4 (CU-8692xtha4)
 */ const deeplSupportedTargetGlossaryLanguageCodes = {
    ["da"]: "da",
    ["de"]: "de",
    ["de_formal"]: "de_formal",
    ["de_informal"]: "de_informal",
    ["en"]: "en",
    ["es"]: "es",
    ["fr"]: "fr",
    ["it"]: "it",
    ["no"]: "nb",
    ["nl"]: "nl",
    ["nl_formal"]: "nl_formal",
    ["nl_informal"]: "nl_informal",
    ["pl"]: "pl",
    ["pt"]: "pt",
    ["rm"]: "ro",
    ["sv"]: "sv"
};
Object.freeze(deeplSupportedTargetGlossaryLanguageCodes);
/**
 * Listing our supported languages mapped to known Weblate language codes.
 */ const weblateLanguageMap = {
    ["cs"]: "cs",
    ["da"]: "da",
    ["nl"]: "nl",
    ["nl_formal"]: "nl@formal",
    ["nl_informal"]: "nl@informal",
    ["en"]: "en",
    ["es"]: "es@formal",
    ["fi"]: "fi",
    ["fr"]: "fr@formal",
    ["de"]: "de",
    ["de_formal"]: "de@formal",
    ["de_informal"]: "de@informal",
    ["el"]: "el",
    ["hu"]: "hu",
    ["it"]: "it@formal",
    ["no"]: "nb_NO",
    ["pl"]: "pl@formal",
    ["pt"]: "pt@formal",
    ["rm"]: "ro",
    ["sk"]: "sk",
    ["sv"]: "sv"
};
Object.freeze(weblateLanguageMap);
/** The T–V distinction is the contextual use of different pronouns that exists in some languages and serves to convey formality or familiarity. */ const TV_DISTINCT_LANGUAGES = [
    "de",
    "nl"
];
/**
 * The default language is English.
 */ const DEFAULT_LANGUAGE = "en";
/**
 * Converts a supported language code to the corresponding Deepl API language code.
 *
 * @param supportedLanguage - The supported language code.
 * @returns - The Deepl API language code, or undefined if the language is not supported.
 */ function getDeeplLanguageCode(supportedLanguage) {
    return deeplLanguageMap[supportedLanguage];
}
/**
 * Converts a supported language code to the corresponding Deepl supported target glossary language code.
 *
 * @param supportedLanguage - The supported language code.
 * @returns - The Deepl supported target glossary language code, or undefined if the language is not supported.
 */ function getDeeplSupportedTargetGlossaryLanguageCode(supportedLanguage) {
    return deeplSupportedTargetGlossaryLanguageCodes[supportedLanguage];
}
/**
 * Converts a supported language code to the corresponding Weblate language code.
 *
 * @param supportedLanguage - The supported language code.
 * @returns - The Weblate language code, or undefined if the language is not supported.
 */ function getWeblateLanguageCode(supportedLanguage) {
    return weblateLanguageMap[supportedLanguage];
}
/**
 * Find supported languages as key string of ESupportedLanguage.
 */ function getSupportedLanguageKeys() {
    return Object.keys(ESupportedLanguage).filter((language)=>{
        return isNaN(Number(language));
    });
}
/**
 * Find supported languages as ESupportedLanguage array.
 */ function getSupportedLanguages() {
    let includeTVDistinctLanguages = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : true;
    let languages = Object.keys(ESupportedLanguage).filter((language)=>{
        return isNaN(Number(language));
    }).map((key)=>ESupportedLanguage[key]);
    if (!includeTVDistinctLanguages) {
        languages = languages.filter((lang)=>!TV_DISTINCT_LANGUAGES.includes(lang));
    }
    return languages;
}
export { DEFAULT_LANGUAGE, TV_DISTINCT_LANGUAGES, getDeeplLanguageCode, getDeeplSupportedTargetGlossaryLanguageCode, getSupportedLanguages, getSupportedLanguageKeys, getWeblateLanguageCode, ESupportedLanguage, routeLocationSupportedLanguagesGet };
